import * as HelperModel from '@/models/news.js'

class NewsHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(data) {
        console.log(data)
        var validator = this.validatorModel()

        validator.hasError = false;
        return validator;
    }

}

const NewsHelper = new NewsHelpers
export default NewsHelper