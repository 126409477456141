<template>
  <div class="pt-5">
      <v-btn
        @click="contentLanguage = 'ka'"
        :class="contentLanguage == 'ka' ? 'primary' : ''"
        >KA</v-btn
      >
      <v-btn
        @click="contentLanguage = 'en'"
        :class="contentLanguage == 'en' ? 'primary' : ''"
        >EN</v-btn
      >

      <v-text-field v-if="contentLanguage == 'ka'" v-model="model.title.ka"/>
      <v-text-field v-if="contentLanguage == 'en'" v-model="model.title.en"/>

      <froala
        v-if="contentLanguage == 'ka'"
        :config="config"
        v-model="model.full_text.ka"
        class="mb-3 mt-5"
      ></froala>
      <froala
        v-if="contentLanguage == 'en'"
        :config="config"
        v-model="model.full_text.en"
        class="mb-3 mt-5"
      ></froala>
  </div>
</template>
<script>
import Auth from "@/helpers/authentication";
export default {
  props: ["model"],
  data() {
    return {
      items: false,
      contentLanguage: this.$i18n.locale,
      config: {
        requestHeaders: {
          "Auth-Token": Auth.isAuthenticated().token,
        },
        immediateVueModelUpdate: true,
        imageUploadURL: "https://admin.gs1ge.org/api/v1//media/upload",
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },

          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote",
            ],
          },
          moreRich: {
            buttons: [
              "insertLink",
              "insertImage",
              "insertVideo",
              "insertTable",
              "emoticons",
              "specialCharacters",
              "insertHR",
            ],
          },

          moreMisc: {
            buttons: ["undo", "redo", "html"],
          },
        },

        // Colors list.
        colorsBackground: [
          "#002C6C",
          "#F26334",
          "#454545",
          "#888B8D",
          "#B1B3B3",
          "#F4F4F4",
          "#F05587",
          "#BD80B7",
          "#AF96D4",
          "#89AADB",
          "#00B6DE",
          "#008DBD",
          "#8DB9CA",
          "#22BCB9",
          "#71B790",
          "#7AC143",
          "#00AC4A",
          "#9DBB68",
          "#C1D82F",
          "#C4B000",
          "#FBB034",
          "#FF8200",
          "#B78B20",
          "#18158",
          "#FFFFFF",
          "REMOVE",
        ],
        colorsDefaultTab: "background",
        colorsStep: 6,
        colorsText: [
          "#002C6C",
          "#F26334",
          "#454545",
          "#888B8D",
          "#B1B3B3",
          "#F4F4F4",
          "#F05587",
          "#BD80B7",
          "#AF96D4",
          "#89AADB",
          "#00B6DE",
          "#008DBD",
          "#8DB9CA",
          "#22BCB9",
          "#71B790",
          "#7AC143",
          "#00AC4A",
          "#9DBB68",
          "#C1D82F",
          "#C4B000",
          "#FBB034",
          "#FF8200",
          "#B78B20",
          "#18158",
          "#FFFFFF",
          "REMOVE",
        ],
        fontSize: ['8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '36'],
        linkStyles: {
          link: "Link",
          btnBlue: "MidnightBlue",
          btnOrange: "Flamingo",
          btnPink: "FrenchRose",
          btnOrange2: "FlushOrange",
          btnBlue2: "BondiBlue",
          btnBlue3: "Cerulean"
        },
        events: {
          initialized: function () {},
          "image.uploaded": function (response) {
            var img_url = JSON.parse(response).url;
            var editor = this;
            editor.image.insert(
              img_url,
              false,
              null,
              editor.image.get(),
              response
            );
            return false;
          },
        },
      },
    };
  },
};
</script>