<template>
  <v-dialog
    v-model="dialogState"
    v-if="newsModel"
    persistent
    max-width="1200px"
    min-height="800px"
  >
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-plus</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.edit") }}
        </v-toolbar-title>
      </v-toolbar>

      <div class="row" style="margin: 0px !important; justify-content: center">
        <div class="col col-md-6">
          <template v-if="tempImage">
            <v-img :src="tempImage.url" class="imgClass" />
          </template>
          <template
            v-if="
              newsModel.title.img &&
              newsModel.title.img.ka.url &&
              newsModel.title.img.ka.url &&
              !tempImage
            "
          >
            <v-img :src="newsModel.title.img.ka.url" style="imgClass" />
          </template>
          <v-file-input
            v-model="tempImage"
            value=""
            show-size
            truncate-length="50"
            :placeholder="$vuetify.lang.t('$vuetify.action.change_banner')"
            accept=".jpeg,.jpg,.png"
            ref="file_ka"
            :loading="loading"
            @change="fileUpload('ka')"
            hint="1140 x 350"
          ></v-file-input>
          <center><b>1140 x 350px</b></center>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.closeNewsPopup(false, false)">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn
          @click="$parent.closeNewsPopup(newsModel, tempImage)"
          :disabled="validator.hasError"
        >
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NewsHelper from "@/helpers/news";
import Api from "@/services/api";
export default {
  components: {},
  data: function () {
    return {
      dialogState: false,
      validator: NewsHelper.validatorModel(),
      tempImage: false,
      loading: false
    };
  },
  methods: {
    fileUpload(locale) {
      if(!this.tempImage || this.tempImage.length < 1) {
        return;
      }
      this.loading = true;
      let f = this.$refs["file_" + locale].$refs.input.files[0];
      let formData = new FormData();
      formData.append("file", f);
      Api.media("upload", formData).then(({ data }) => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.manager.imported"));
        this.tempImage = data;
        this.loading = false
      });
    },
  },
  watch: {
    newsImagePopup(newVal) {
      this.dialogState = newVal;
      this.loading = false;
      this.tempImage = false;
    },
  },
  props: ["newsImagePopup", "newsModel"],
};
</script>
<style lang="css" scoped>
.imgClass {
  max-width: 100%;
  max-height: 56%;
  width: 100%;
  height: 56%;
}
</style>