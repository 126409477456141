export const validator = {
    hasError: false,
}

export const newItem = {
    title: {
        ka: "",
        en: "",
        img: {
            ka: {},
            en: {}
        }
    },
    short_text: {
        ka: null,
        en: null
    },
    full_text: {
        ka: "",
        en: ""
    },
    hidden: true,
}