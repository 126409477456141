<template>
  <div>
    <v-toolbar outlined>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.site.news")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="newsPopupShow()">
        <v-icon>mdi-plus</v-icon> {{ $vuetify.lang.t("$vuetify.action.add") }}
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'article/news/list'"
          :headers="headers"
          :title="false"
          :sortBy="sortBy"
          :sortDesc="sortDesc"
          icon="mdi-briefcase-download"
          tableType="news"
          :hideFilters="true"
          :reload=reload
        />
      </v-col>
    </v-row>
    <NewsImage :newsImagePopup="newsImagePopup" :newsModel="newsModel" />
    <News :newsPopup="newsPopup" :newsModel="newsModel" />
  </div>
</template>

<script>
import News from "@/components/manager/popup/site/News";
import NewsImage from "@/components/manager/popup/site/NewsImage";
import Table from "@/components/shared/Table";
import Api from "@/services/api";
export default {
  components: {
    Table,
    News,
    NewsImage
  },
  data: function () {
    return {
      sortBy: ["id"],
      sortDesc: [true],
      newsPopup: false,
      newsImagePopup: false,
      reload:1,
      newsModel: false
    };
  },
  methods: {
    newsImagePopupShow(data) {
      this.newsImagePopup = true;
      this.newsModel = data
    },
    newsPopupShow(data) {
      this.newsPopup = true;
      this.newsModel = data
    },
    closeNewsPopup(item, image) {
      this.newsPopup = false;
      this.newsImagePopup = false;

      if (!item) {
        return;
      }

      if(image) {
        item.title.img = {
          ka: image
        }
      }

      if (!item.id) {
        Api.article("news/add", item).then(() => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.action.update_success")
          );
          this.saveItemsLoading = false;
          ++this.reload
        });
      }

      if (item.id) {
        Api.article("save", item).then(() => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.action.update_success")
          );
          this.saveItemsLoading = false;
          ++this.reload
        });
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.tables.id"),
          value: "id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.title"),
          value: "title",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action.action"),
          value: "newsActions",
          sortable: false
        }
      ];
    },
  }
};
</script>