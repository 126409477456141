<template>
  <v-dialog v-model="dialogState" v-if="items" persistent max-width="1200px" min-height="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-plus</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <Editor :model="items" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.closeNewsPopup(false)">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="$parent.closeNewsPopup(items)" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NewsHelper from "@/helpers/news";
import Editor from "@/components/manager/site/News.vue";
export default {
  components: {
    Editor
  },
  data: function () {
    return {
      dialogState: false,
      itemData: false,
      validator: NewsHelper.validatorModel(),
      items: false,
    };
  },
  methods: {
    validateForm() {
      this.validator = NewsHelper.validateForm(this.itemData);
    },
  },
  watch: {
    newsPopup(newVal) {
      this.dialogState = newVal;
      this.items = this.newsModel ? this.newsModel : new NewsHelper.newItemModel();
    }
  },
  props: ["newsPopup","newsModel"],
};
</script>